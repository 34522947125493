import { css } from '@emotion/react';
import React from 'react';

import config from '../../configs/websiteConfig';
import { useStaticLogoQuery } from '../../graphql/useStaticLogoQuery';

const SubscribeLogo = (): JSX.Element | null => {
  const src = useStaticLogoQuery();
  if (!src) {
    return null;
  }

  return (
    <img
      css={SubscribeOverlayLogo}
      className="subscribe-overlay-logo"
      src={src}
      alt={config.title}
    />
  );
};

const SubscribeOverlayLogo = css`
  position: fixed;
  top: 23px;
  left: 30px;
  height: 30px;
`;

export default SubscribeLogo;
