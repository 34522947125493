import { MicrodataHelper } from '../components/seo/MicrodataHelmet';

import Config from './websiteConfig';

const buildMicrodataHelper = () => {
  const urls: string[] = [];
  if (Config.externalUrls.facebook) {
    urls.push(Config.externalUrls.facebook);
  }

  if (Config.externalUrls.instagram) {
    urls.push(Config.externalUrls.instagram);
  }

  if (Config.externalUrls.twitter) {
    urls.push(Config.externalUrls.twitter);
  }

  if (Config.externalUrls.webSite) {
    urls.push(Config.externalUrls.webSite);
  }

  return new MicrodataHelper({
    title: Config.title,
    description: Config.description,
    siteUrl: Config.siteUrl,
    lang: Config.lang,
    logoPath: '',
    searchPathWithTerm: Config.searchPagePathWithVariable,
    entryPoints: urls,
  });
};

export default buildMicrodataHelper();
