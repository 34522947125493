import { parseSearchPathWithTerm, stripUndefinedKeys } from './utils';

export interface IConstructorOptions {
  title: string;
  description: string;
  lang: string;
  siteUrl: string;
  logoPath: string;
  searchPathWithTerm?: string;
  entryPoints?: string[];
}

export interface IOrganizationMicrodata {
  title?: string;
  entryPoints?: string[] | null;
  logoUrl?: string;
}

export interface IArticleMicrodata {
  tags: string[];
  title: string;
  postedAt: string;
  updatedAt: string;
  image?: string;
  author: string;
  authorPage: string;
  authorImage?: string;
  pageUrl: string;
}

export interface IImageMicrodata {
  image: string;
  title: string;
  width?: number;
  height?: number;
}

export interface IWebsiteMicrodata {
  name?: string;
  description?: string;
}

export interface IAuthorMicrodata {
  author: string;
  authorPage: string;
  image?: string;
}

export interface IWebPageMicrodata {
  pageTitle: string;
  postedAt: string;
  updatedAt: string;
  withPartOfWebsite?: boolean;
  image?: string;
}

export interface IMicrodataHelper {
  setLogoPath: (logoPath: string) => void;
  generateOrganizationMicrodata: (options: IOrganizationMicrodata) => Record<string, any>;
  generateArticleMicrodata: (options: IArticleMicrodata) => Record<string, any>;
  generateAuthorMicrodata: (options: IAuthorMicrodata) => Record<string, any>;
  generateImageMicrodata: (options: IImageMicrodata) => Record<string, any>;
  generateSearchActionMicrodata: () => Record<string, any> | undefined;
  generateWebsiteMicrodata: (options: IWebsiteMicrodata) => Record<string, any>;
  generateWebPageMicrodata: (options: IWebPageMicrodata) => Record<string, any>;
  generateReadActionMicrodata: () => Record<string, any>;
}

export default class MicrodataHelper implements IMicrodataHelper {
  private readonly config: {
    title: string;
    description: string;
    siteUrl: string;
    logoUrl: string;
    lang: string;
    search: {
      urlWithTerm: string;
      term: string;
    } | null;
    entryPoints: string[] | null;
  };

  constructor({
    title,
    description,
    siteUrl,
    logoPath,
    lang,
    searchPathWithTerm,
    entryPoints,
  }: IConstructorOptions) {
    const siteUrlWithoutLastSlash = siteUrl.replace(/\/$/, '');
    this.config = {
      title,
      description,
      siteUrl,
      logoUrl: siteUrlWithoutLastSlash + '/' + logoPath,
      lang,
      search: searchPathWithTerm
        ? parseSearchPathWithTerm(siteUrlWithoutLastSlash + '/' + searchPathWithTerm)
        : null,
      entryPoints: entryPoints && entryPoints.length > 0 ? entryPoints : null,
    };
  }

  setLogoPath(logoPath: string): void {
    const siteUrlWithoutLastSlash = this.config.siteUrl.replace(/\/$/, '');

    this.config.logoUrl = siteUrlWithoutLastSlash + '/' + logoPath;
  }

  /**
   * Organization
   */
  generateOrganizationMicrodata({
    title = this.config.title,
    logoUrl = this.config.logoUrl,
    entryPoints = this.config.entryPoints,
  }: IOrganizationMicrodata): Record<string, any> {
    const mainLogo = this.generateImageMicrodata({ image: logoUrl, title });
    const object = {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      '@id': this.config.siteUrl,
      url: this.config.siteUrl,
      name: title,
      sameAs: entryPoints ?? undefined,
      logo: mainLogo,
      image: mainLogo,
    };

    return stripUndefinedKeys(object);
  }

  /**
   * Article
   */
  generateArticleMicrodata({
    tags,
    title,
    postedAt,
    updatedAt,
    image,
    authorImage,
    author,
    authorPage,
    pageUrl,
  }: IArticleMicrodata): Record<string, any> {
    const webPage = this.generateWebPageMicrodata({
      pageTitle: title,
      image,
      postedAt,
      updatedAt,
      withPartOfWebsite: true,
    });

    const object: Record<string, any> = {
      '@context': 'https://schema.org/',
      '@type': 'Article',
      '@id': pageUrl,
      url: pageUrl,
      headline: title,
      datePublished: postedAt,
      dateModified: updatedAt,
      commentCount: 0,
      articleSection: tags[0],
      inLanguage: this.config.lang,
      isPartOf: webPage,
      author: this.generateAuthorMicrodata({ author, authorPage, image: authorImage }),
      mainEntityOfPage: webPage,
      publisher: this.generateOrganizationMicrodata({}),
      image: image
        ? this.generateImageMicrodata({ image, title })
        : this.generateImageMicrodata({ image: this.config.logoUrl, title }),
    };

    return stripUndefinedKeys(object);
  }

  /**
   * Author
   */
  generateAuthorMicrodata({ author, authorPage, image }: IAuthorMicrodata): Record<string, any> {
    const object = {
      '@context': 'http://schema.org',
      '@type': 'Person',
      '@id': authorPage,
      name: author,
      image: image ? this.generateImageMicrodata({ image, title: author }) : undefined,
    };
    return stripUndefinedKeys(object);
  }

  /**
   * Image
   */
  generateImageMicrodata({ image, title, width, height }: IImageMicrodata): Record<string, any> {
    const object = {
      '@context': 'http://schema.org',
      '@type': 'ImageObject',
      '@id': image,
      url: image,
      caption: title,
      width: width && height ? width : undefined,
      height: width && height ? height : undefined,
    };

    return stripUndefinedKeys(object);
  }

  /**
   * SearchAction
   */
  generateSearchActionMicrodata(): Record<string, any> | undefined {
    if (!this.config.search?.urlWithTerm || !this.config.search?.term) {
      return undefined;
    }

    const object = {
      '@context': 'http://schema.org',
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: this.config.search.urlWithTerm,
      },
      'query-input': {
        '@type': 'PropertyValueSpecification',
        valueRequired: 'http://schema.org/True',
        valueName: this.config.search.term,
      },
    };

    return stripUndefinedKeys(object);
  }

  /**
   * Website
   */
  generateWebsiteMicrodata({
    name = this.config.title,
    description = this.config.description,
  }: IWebsiteMicrodata): Record<string, any> {
    const object = {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      '@id': this.config.siteUrl,
      url: this.config.siteUrl,
      name,
      description,
      inLanguage: this.config.lang,
      publisher: this.generateOrganizationMicrodata({}),
      potentialAction: this.generateSearchActionMicrodata(),
    };

    return stripUndefinedKeys(object);
  }

  /**
   * Web Page
   */
  generateWebPageMicrodata({
    pageTitle,
    postedAt,
    updatedAt,
    withPartOfWebsite = false,
    image,
  }: IWebPageMicrodata): Record<string, any> {
    const object = {
      '@context': 'http://schema.org',
      '@type': 'WebPage',
      '@id': this.config.siteUrl,
      url: this.config.siteUrl,
      name: pageTitle,
      datePublished: postedAt,
      dateModified: updatedAt,
      inLanguage: this.config.lang,
      isPartOf: withPartOfWebsite ? this.generateWebsiteMicrodata({}) : undefined,
      primaryImageOfPage: image
        ? this.generateImageMicrodata({ image, title: pageTitle })
        : undefined,
      // potentialAction: generateReadActionMicrodata(),
    };

    return stripUndefinedKeys(object);
  }

  /**
   * Read Action
   */
  generateReadActionMicrodata(): Record<string, any> {
    const object = {
      '@context': 'http://schema.org',
      '@type': 'ReadAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: this.config.siteUrl,
      },
    };

    return stripUndefinedKeys(object);
  }
}
