export interface IWebsiteConfig {
  title: string;
  description: string;
  footer?: string;
  /**
   * Specifying a valid BCP 47 language helps screen readers announce text properly.
   * See: https://dequeuniversity.com/rules/axe/2.2/valid-lang
   */
  lang: string;
  /**
   * blog full path, no ending slash!
   */
  siteUrl: string;
  /**
   * For seo, format : "?s={search_term_string}"
   */
  searchPagePathWithVariable?: string;
  externalUrls: {
    webSite?: string;
    facebook?: string;
    twitter?: string;
    instagram?: string;
    appStore?: string;
    googlePlay?: string;
  };
  /**
   * Colors
   */
  colors: {
    primary: string;
  };
  /**
   * hide or show all email subscribe boxes
   */
  showSubscribe: boolean;
  /**
   * create a list on mailchimp and then create an embeddable signup form. this is the form action
   */
  mailchimp: {
    action?: string;
    hiddenId?: string;
    hiddenU?: string;
    emailFieldName?: string;
  };

  /**
   * Meta tag for Google Webmaster Tools
   */
  googleSiteVerification?: string;
  /**
   * If analytics is enabled
   */
  googleAnalyticsId?: string;
}

const REQUIRED_VARIABLES = ['title', 'description', 'lang', 'siteUrl', 'showSubscribe'];

function checkRequiredVariables(config: Record<string, any>): void {
  REQUIRED_VARIABLES.forEach((key): void => {
    if (!config[key] || config[key] === '') {
      throw new Error(`${key} variable is required`);
    }
  });
}

function parseConfig(config: Record<string, any>): IWebsiteConfig {
  return {
    title: config.title,
    description: config.description,
    footer: config.footer,
    lang: config.lang,
    siteUrl: config.siteUrl,
    searchPagePathWithVariable: config.searchPagePathWithVariable,
    googleAnalyticsId: config.googleAnalyticsId,
    googleSiteVerification: config.googleSiteVerification,
    externalUrls: {
      webSite: config.externalUrls ? config.externalUrls.webSite : null,
      instagram: config.externalUrls ? config.externalUrls.instagram : null,
      facebook: config.externalUrls ? config.externalUrls.facebook : null,
      twitter: config.externalUrls ? config.externalUrls.twitter : null,
      googlePlay: config.externalUrls ? config.externalUrls.googlePlay : null,
      appStore: config.externalUrls ? config.externalUrls.appStore : null,
    },
    colors: {
      primary: config.colors.primary || '#ff5733',
    },
    showSubscribe: config.showSubscribe && config.mailchimp && config.mailchimp.action,
    mailchimp: {
      action: config.mailchimp ? config.mailchimp.action : null,
      hiddenU: config.mailchimp ? config.mailchimp.hiddenU : null,
      hiddenId: config.mailchimp ? config.mailchimp.hiddenId : null,
      emailFieldName: config.mailchimp ? config.mailchimp.emailFieldName : null,
    },
  };
}

function buildConfig(config: any): IWebsiteConfig {
  checkRequiredVariables(config);
  return parseConfig(config);
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
export default buildConfig(require('../../content/config/config.json'));
