import React from 'react';

import StaticMicrodataInitializer from '../components/seo/MicrodataHelmet/StaticMicrodataInitializer';
import DefaultStyles from '../providers/ThemeProvider/DefaultStyles';
import FontStyles from '../providers/ThemeProvider/FontStyles';

interface IndexProps {
  className?: string;
}

const IndexLayout: React.FC<IndexProps> = props => {
  return (
    <div className={props.className}>
      <StaticMicrodataInitializer />
      <DefaultStyles />
      <FontStyles />
      {props.children}
    </div>
  );
};

export default IndexLayout;
