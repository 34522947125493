import { css } from '@emotion/react';
import { Link } from 'gatsby';
import { darken } from 'polished';
import React from 'react';

import config from '../../configs/websiteConfig';
import { Translate } from '../../locales/i18n';
import { colors } from '../../styles/colors';
import { SocialLink } from '../../styles/shared';
import Icon from '../common/Icon';
import { SubscribeModal } from '../subscribe/SubscribeModal';

import { StaticLogo } from './StaticLogo';
import {
  SiteNavStyles,
  SiteNavLeft,
  SiteNavContent,
  NavStyles,
  SiteNavRight,
  SocialLinks,
  SubscribeButton,
  NavPostTitle,
  HideNav,
} from './styled';
import { getAvailableSocialMedias } from './utils';

interface SiteNavProps {
  isHome?: boolean;
  isPost?: boolean;
  post?: any;
}

interface SiteNavState {
  showTitle: boolean;
}

class SiteNav extends React.Component<SiteNavProps, SiteNavState> {
  subscribe = React.createRef<SubscribeModal>();
  titleRef = React.createRef<HTMLSpanElement>();
  lastScrollY = 0;
  ticking = false;
  state = { showTitle: false };

  openModal = (): void => {
    if (this.subscribe.current) {
      this.subscribe.current.open();
    }
  };

  componentDidMount(): void {
    this.lastScrollY = window.scrollY;
    if (this.props.isPost) {
      window.addEventListener('scroll', this.onScroll, { passive: true });
    }
  }

  componentWillUnmount(): void {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = (): void => {
    if (!this.titleRef || !this.titleRef.current) {
      return;
    }

    if (!this.ticking) {
      requestAnimationFrame(this.update);
    }

    this.ticking = true;
  };

  update = (): void => {
    if (!this.titleRef || !this.titleRef.current) {
      return;
    }

    this.lastScrollY = window.scrollY;

    const trigger = this.titleRef.current.getBoundingClientRect().top;
    const triggerOffset = this.titleRef.current.offsetHeight + 35;

    // show/hide post title
    if (this.lastScrollY >= trigger + triggerOffset) {
      this.setState({ showTitle: true });
    } else {
      this.setState({ showTitle: false });
    }

    this.ticking = false;
  };

  render(): JSX.Element {
    const { isHome = false, isPost = false, post = {} } = this.props;

    return (
      <>
        {config.showSubscribe && <SubscribeModal ref={this.subscribe} />}
        <nav css={SiteNavStyles}>
          <SiteNavLeft className="site-nav-left">
            {!isHome && <StaticLogo />}
            <SiteNavContent css={[this.state.showTitle ? HideNav : '']}>
              <ul css={NavStyles} role="menu">
                {/* TODO: mark current nav item - add class nav-current */}
                <li role="menuitem">
                  <Link to="/">{Translate.page.home}</Link>
                </li>
                <li role="menuitem">
                  <Link to="/about">{Translate.page.about}</Link>
                </li>
              </ul>
              {isPost && (
                <NavPostTitle ref={this.titleRef} className="nav-post-title">
                  {post.title}
                </NavPostTitle>
              )}
            </SiteNavContent>
          </SiteNavLeft>
          <SiteNavRight>
            <SocialLinks>
              {getAvailableSocialMedias().map(socialMedia => (
                <a
                  key={socialMedia.title}
                  css={SocialLink}
                  href={socialMedia.url}
                  target="_blank"
                  title={socialMedia.title}
                  rel="noopener noreferrer"
                  className={socialMedia.mobile ? 'no-hide' : ''}
                >
                  <Icon name={socialMedia.icon} color="white" />
                </a>
              ))}
            </SocialLinks>
            {config.showSubscribe && (
              <SubscribeButton onClick={this.openModal}>
                {Translate.subscribe.action}
              </SubscribeButton>
            )}
          </SiteNavRight>
        </nav>
      </>
    );
  }
}

export const SiteNavMain = css`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  /* background: color(var(--darkgrey) l(-5%)) */
  background: ${darken('0.05', colors.darkgrey)};

  @media (max-width: 700px) {
    padding-right: 0;
    padding-left: 0;
  }
`;

export default SiteNav;
