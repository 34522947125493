import { Global, css } from '@emotion/react';
import React from 'react';

import icomoonWOFF from '../../assets/fonts/icomoon.woff';

/* * {
  font-family: 'SFUIDisplay', Arial, "Liberation Sans", FreeSans, sans-serif;
} */

const FontStyles: React.FC = () => {
  const styles = css`
    @font-face {
      font-family: 'icomoon';
      src: ${`url(${icomoonWOFF})`} format('truetype');
      font-weight: normal;
      font-style: normal;
      font-display: block;
    }
  `;

  return <Global styles={styles} />;
};

export default FontStyles;
