import { graphql, useStaticQuery } from 'gatsby';
import { FixedObject } from 'gatsby-image';

interface ILogoQueryResult {
  logo?: {
    childImageSharp: {
      fixed: FixedObject;
    };
  };
}

export const useStaticLogoQuery = (): string | null => {
  const ImageData: ILogoQueryResult = useStaticQuery(graphql`
    query {
      logo: file(absolutePath: { regex: "/theme/logo.png/" }) {
        childImageSharp {
          fixed(quality: 100, width: 500) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return ImageData.logo && ImageData.logo.childImageSharp
    ? ImageData.logo.childImageSharp.fixed.src
    : null;
};
