import MicrodataConfig from '../../../configs/microdata';
import { useStaticLogoQuery } from '../../../graphql/useStaticLogoQuery';

const StaticMicrodataInitializer = (): null => {
  const logoPath = useStaticLogoQuery();
  if (logoPath) {
    MicrodataConfig.setLogoPath(logoPath);
  }

  return null;
};

export default StaticMicrodataInitializer;
