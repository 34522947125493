import React from 'react';
import { Helmet } from 'react-helmet';

export interface MicrodataProps {
  microdata: Record<string, any>;
}

const Microdata: React.FC<MicrodataProps> = ({ microdata }) => {
  if (!microdata) {
    return null;
  }

  return (
    <Helmet encodeSpecialCharacters={false}>
      <script type="application/ld+json">{JSON.stringify(microdata)}</script>
    </Helmet>
  );
};

export default Microdata;
