export const Translate = {
  subscribe: {
    action: "S'inscrire",
    to: "S'inscrire au",
    description: "Abonnez-vous pour connaître les dernières nouveautés de Mon Resto' Halal.",
    placeholder: 'votre.email@exemple.com',
    cta: "Toute les dernières actualités autour de Mon Resto' Halal dans votre boite email.",
  },
  page: {
    home: 'Accueil',
    about: 'À propos',
    latestPosts: 'Dernières publications',
  },
  about: {
    title: 'À propos de nous',
  },
  error: {
    notFound: 'Page non trouvée',
  },
  action: {
    backToHome: "Retourner à la page d'accueil →",
    minRead: 'min de lecture',
  },
  posts: {
    moreIn: 'Encore plus dans',
    see: 'Voir les',
    collection: 'Une collection de',
    post: 'publication',
    posts: 'publications',
    noPost: 'aucune publication',
    morePost: 'Plus de publications',
    by: 'par',
  },
};
