import Config from '../configs/websiteConfig';

export const colors = {
  primary: Config.colors.primary || '#ff5733',
  darkgrey: '#15171A',
  midgrey: '#737373',
  lightgrey: '#c5d2d9',
  whitegrey: '#e5eff5',
  darkmode: '#191b1f',
};
