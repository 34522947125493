/* eslint-disable @typescript-eslint/no-explicit-any */
export const stripUndefinedKeys = (obj: Record<string, any>): Record<string, any> => {
  return Object.keys(obj).reduce((acc, key) => {
    const _acc: Record<string, any> = acc;
    if (obj[key] !== undefined) {
      _acc[key] = obj[key];
    }

    return _acc;
  }, {});
};

export const parseSearchPathWithTerm = (
  url: string,
): { urlWithTerm: string; term: string } | null => {
  const regex = RegExp(/\{(.*?)\}/);
  const matches = regex.exec(url);

  if (matches) {
    return {
      urlWithTerm: url,
      term: matches[1],
    };
  }

  return null;
};
