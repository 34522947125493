import React from 'react';
import IcoMoon from 'react-icomoon';

const iconSet = require('./selection.json');

export interface IIconProps {
  color: string;
  name: string;
  onClick?: () => void;
  style?: Record<string, any>;
}

const Icon: React.FC<IIconProps> = ({ color, name, style = {}, onClick }) => {
  const generatedStyle = { ...style };

  return (
    <IcoMoon onClick={onClick} iconSet={iconSet} icon={name} color={color} style={generatedStyle} />
  );
};

export default Icon;
