import Config from '../../configs/websiteConfig';

interface ISocialMedia {
  title: string;
  url: string;
  icon: string;
  mobile?: boolean;
}

export const getAvailableSocialMedias = (): ISocialMedia[] => {
  const socialMedias: ISocialMedia[] = [];

  if (Config.externalUrls.webSite) {
    socialMedias.push({
      title: 'Site',
      url: Config.externalUrls.webSite,
      icon: 'web',
      mobile: true,
    });
  }

  if (Config.externalUrls.googlePlay) {
    socialMedias.push({
      title: 'Android - Play Store',
      url: Config.externalUrls.googlePlay,
      icon: 'android',
    });
  }

  if (Config.externalUrls.appStore) {
    socialMedias.push({
      title: 'iOS - AppStore',
      url: Config.externalUrls.appStore,
      icon: 'apple',
    });
  }

  if (Config.externalUrls.facebook) {
    socialMedias.push({ title: 'Facebook', url: Config.externalUrls.facebook, icon: 'facebook' });
  }

  if (Config.externalUrls.twitter) {
    socialMedias.push({ title: 'Twitter', url: Config.externalUrls.twitter, icon: 'twitter' });
  }

  if (Config.externalUrls.instagram) {
    socialMedias.push({
      title: 'Instagram',
      url: Config.externalUrls.instagram,
      icon: 'instagram',
    });
  }

  return socialMedias;
};
